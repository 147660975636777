<template>
	<main>
<section class="hero">
                <div class="container">
                    <div class="hero-inner">
						<div class="hero-copy">
	                        <div class="container d-flex justify-content-center profile-edit">
    <div class="card p-3">
        <div class="d-flex align-items-center">
        	<button class="btn btn-info" @click="EditHandler('image')"><span class="fa fa-pen"></span>
            	</button>&nbsp;
            <div class="image">
            	<img :src=" user.Avatar ? $imgPath + user.Avatar : '/img/avatar.png'" class="rounded" width="155">
            	<h1>&nbsp;</h1>
            	<form v-if="imageEditor">
					<div class="input-group col-md-12">
						<input type="file" class="form-control" ref="avatar" @change="handleFileUpload()"/>
						<span class="input-group-append">

							<button type="submit" v-if="file" @click="submitFile" class="btn btn-success"><i class="fa fa-check"></i></button>
							<button type="button" @click="cancelImageEditor" class="btn btn-warning"><i class="fa fa-times"></i></button>
						</span>
					</div>
				</form>
            </div>
	
            <div class="ml-3 w-100">
                <h4 class="mb-0 mt-0">
                	Username:
                	<div class="input-group col-md-12">
			

                	<input type="text" id="username" :placeholder="user.UserName" class="form-control" disabled/>
                	<span class="input-group-append">
                	 <button class="btn btn-info" @click="EditHandler('name')"><span class="fa fa-pen"></span></button>
                	</span>
          			</div>
                	<form v-if="nameEditor">
					 <div class="input-group col-md-12">
						<input type="text" ref="username" @change="handleUserNameChange" class="form-control" />
						<span class="input-group-append">
							<button type="submit" v-if="username" @click="submitUserName" class="btn btn-success"><i class="fa fa-check"></i></button>
							<button type="button" @click="cancelUserNameEditor" class="btn btn-warning"><i class="fa fa-times"></i></button>
					</span>
					</div>
            	</form>
                </h4> 
              <h4 class="mb-0 mt-0">
                	<form>
					 	Password:

					 <div class="input-group col-md-12">
            			<input type="password" id="password" ref="password" v-model="password" class="form-control" :disabled="!passwordEditor" placeholder="Password" required pattern=".{6,25}" />
						<span class="input-group-append">
							<button class="btn btn-info" @click="EditHandler('password')"><span class="fa fa-pen"></span></button>
            				<button type="submit" v-if="password" @click="submitPassword" class="btn btn-success"><i class="fa fa-check"></i></button>
            				<button type="button" v-if="passwordEditor" @click="cancelPasswordEditor" class="btn btn-warning"><i class="fa fa-times"></i></button>
            			</span>
            		</div>
            	</form>
                </h4>
            </div>
        </div>
    </div>
</div>
						</div>
						<div class="hero-figure anime-element">
							<svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
								<rect width="528" height="396" style="fill:transparent;" />
							</svg>
							<div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
							<div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
							<div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
							<div class="hero-figure-box hero-figure-box-05"></div>
							<div class="hero-figure-box hero-figure-box-06">
								<button class="box-btn" v-if="!$user.IsAuthenticated" @click="loginToPlayLudi" title="Login to play Ludi"> 
                        			<i class="fa fa-dice fa-5x"></i>
                  				</button>
                  				<button v-else class="box-btn" @click="playLudi" > 
			                    	<i class="fa fa-dice fa-5x"></i>
			                  	</button>
							</div>
							<div class="hero-figure-box hero-figure-box-07"></div>
							<div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
							<div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
							<div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
						</div>
                    </div>
                </div>
            </section>
  

<Footer></Footer>
</main>
</template>


<script>
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

	export default {
		name: 'Profile',
		components: {
			Footer
		}, setup(){
            useMeta({
              title: 'LudiJam - Profile',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
		data() {
			return {
				user: this.$user,
				nameEditor: false,
				imageEditor: false,
				passwordEditor: false,
				password: "",
				file:'',
				username: '',
			    optionAxios: this.$httpOptions
			}
		},created(){
            //start hub connection
            //this.$sigcon.start().catch(e => console.log(e));
        	
        },
		mounted(){

		},
		methods: {
			EditHandler(mode){
				if(mode == 'name'){
					this.nameEditor = true
				}else{
					if(mode=='image'){
						this.imageEditor = true
					}else{
						this.passwordEditor = true;
					}
				}
			},playLudi(){
                if(this.$user.IsAuthenticated){
                    this.playGame = true;
                    localStorage.setItem('playStarted',true);
                    window.location.href = "/";
                }else{
                    this.playGame = false;
                    localStorage.removeItem('playStarted');
                }
            },
			submitFile(e){
				e.preventDefault();
	            let formData = new FormData();
	            formData.append('file', this.file);
	            this.$http.post( '/user/uploadProfileImage',
	                formData,
	                {
	                headers: {
	                    'Content-Type': 'multipart/form-data'
	                }
	              }
	            ).then(response => {
	            	let avatar_ = response.data.Data;
					this.user.Avatar = avatar_;
					localStorage.setItem('user', JSON.stringify(this.user));
					this.$toast.success("Image upload successfully.");
					window.location.href ="/profile";
		        })
		        .catch(function(){
		          this.$toast.error("Ooops! Image upload failed. Try Again");
		          
		        });
		      },
      		handleFileUpload(){
	        	this.file = this.$refs.avatar.files[0];
	    	},
	    	handleUserNameChange(){
	    		this.username = this.$refs.username.value;
	    	},submitUserName(e){
				e.preventDefault();
				let profile_ = this;
	    		this.$http.post( '/user/setUserName',{"UserName":this.username},this.optionAxios).then(()=>{
	    			this.$toast.success("Edit successful.");
	    			profile_.user.UserName = this.username
					localStorage.setItem('user', JSON.stringify(this.user));
					window.location.href ="/profile";
	    		}).catch(() => {
	    			this.$toast.error("Username Taken.");
	    		})
	    	},
	    	cancelImageEditor(){
	    		this.file = "";
	    		this.imageEditor = false;
	    	},
	    	cancelUserNameEditor(){
	    		this.username = "";
	    		this.nameEditor = false;
	    	},cancelPasswordEditor(){
	    		this.passwordEditor = false;
	    		this.password = ""; 
	    	},submitPassword(e){
				e.preventDefault();
				let login_ = this;
				login_.$toast.show("<span class='fa fa-spinner fa-2x'></span> Loading....",{
                    position: 'bottom'
                });
				login_.$http.post("/user/UpdateUserPassword",{"Password":this.password},this.$httpOptions)
				.then(()=>{
					login_.$toast.success("Password has been updated.");
					localStorage.removeItem("jwt");
					localStorage.removeItem('user');
                    localStorage.removeItem('token_expiry');
                    this.$http.get('/user/Logout').then(() => {
                        window.location.reload(true);
                     });
				
				}).catch(() =>{
					login_.$toast.clear();
					login_.$toast.error("Unable to update password token. Please refresh and try again.");
				});
	    	},
	    	getNow: function() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            }
		}
	};
</script>
<style scoped>
	body {
    background-color: #B3E5FC;
    border-radius: 10px
}

</style>